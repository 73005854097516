@import '@app/assets/css/text-style.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    --foreground-rgb: 0, 0, 0;
    --background-start-rgb: 214, 219, 220;
    --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
    :root {
        --foreground-rgb: 255, 255, 255;
        --background-start-rgb: 0, 0, 0;
        --background-end-rgb: 0, 0, 0;
    }
}

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Inter', sans-serif;
    --font-sans-serif: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    --font-serif: Georgia, Times, serif;
    --font-mono: Menlo, Courier, monospace;
}

.flip {
    transform: scaleX(-1);
}

.uploadBoxShadow {
    box-shadow: 0px 0px 30px 0px rgba(0, 102, 219, 0.2);
}

::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 10px;
}

.logsShadow {
    box-shadow: 0px 0px 30px 0px rgba(0, 102, 219, 0.2);
}

.\!h-tableHeight {
    height: calc(100vh - 240px + 32px - 116px) !important;
}
