.h1 {
    @apply text-[32px] font-bold leading-[48px] text-black-1;
}

.h2 {
    @apply text-[24px] font-semibold leading-[36px];
}

.h3 {
    @apply text-[20px] font-semibold leading-[30px];
}

.h4 {
    @apply text-[16px] font-medium leading-[24px];
}

.h5 {
    @apply text-[14px] font-semibold leading-[21px];
}

.p1 {
    @apply text-[16px] font-normal leading-[24px];
}

.p2 {
    @apply text-[14px] leading-[21px];
}

.p3 {
    @apply p2 font-medium;
}

.p4 {
    @apply text-[12px] leading-[18px];
}

.label {
    @apply text-[16px] font-semibold leading-[24px];
}

.buttonText1 {
    @apply text-[16px] font-medium leading-[24px];
}

.buttonText2 {
    @apply text-[14px] font-medium  leading-[21px];
}

.buttonText3 {
    @apply text-[12px] font-medium  leading-[18px];
}

.h {
    @apply text-[64px] font-medium leading-[21px] !text-blue-500;
}
.document-text {
    text-wrap: wrap;
}

@media (min-width: 768px) {
    .heroTitle {
        letter-spacing: -0.55px;
        @apply text-[50px] font-bold leading-[80px];
    }
    .heroSubtitle {
        @apply h2;
    }
}

@media (max-width: 768px) {
    .heroTitle {
        @apply h2;
    }
    .heroSubtitle {
        @apply h4;
    }
}
